import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from "react-router-dom";

export default function RequireAuth({ children }) {
    const authed = useSelector((state) => state.users.loggedIn);

    return authed === true
        ? children
        : <Navigate to="/signin" replace />;
}