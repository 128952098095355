import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { connect } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Localization from "../../utils/localization";

import Header from "../../components/Header";
import FilledButton from "../../components/FilledButton";
import OutlinedButton from "../../components/OutlinedButton";
import LightCard from "../../components/LightCard";
import { userActions, statsActions } from "../../redux/actions/";

import instagramLogo from "../../assets/images/instagram_icon_600.png";
import facebookLogo from "../../assets/images/facebook_icon_512.png";
import tiktokLogo from "../../assets/images/tiktok_icon_1000.png";
import youtubeLogo from "../../assets/images/youtube_icon_1000.png";

const AccountOverview = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { usernameParam } = useParams();
    const user = useSelector((state) => state.stats.stats[usernameParam]);
    const stats = useSelector((state) => state.stats);

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(statsActions.fetchBasicPageInfos(usernameParam));
    }, []);

    const renderOverall = () => {
        if (user && user.overallInfos) {
            return (
                <Box
                    sx={{
                        display: "flex",
                        width: 1,
                        paddingLeft: "1rem",
                        paddingRight: "1rem",
                    }}>
                    <LightCard
                        lightColor="primary.light"
                        darkColor="primary.dark"
                        title={Localization.accountOverview.totalreach}
                        text={user.overallInfos.totalReach}></LightCard>
                    {/*<LightCard
                    lightColor="primary.light"
                    darkColor="primary.dark"
                    title={Localization.accountOverview.genderdistribution}
                    text={"125.6k"}
                  ></LightCard>*/}
                </Box>
            );
        } else {
            return (
                <Box
                    sx={{
                        display: "flex",
                        width: 1,
                        paddingLeft: "1rem",
                        paddingRight: "1rem",
                    }}>
                    <Typography textAlign="center">{Localization.accountOverview.noinfoyet}</Typography>
                </Box>
            );
        }
    };

    const renderSocials = () => {
        let accountDisplayed = false;
        let instagramDisplayed = false;
        let facebookDisplayed = false;
        let tiktokDisplayed = false;
        let youtubeDisplayed = false;
        if (user && user.instagramAccounts && user.instagramAccounts.length > 0) {
            accountDisplayed = true;
            instagramDisplayed = true;
        }
        if (user && user.tiktokAccounts && user.tiktokAccounts.length > 0) {
            accountDisplayed = true;
            tiktokDisplayed = true;
        }
        if (user && user.facebookAccounts && user.facebookAccounts.length > 0) {
            accountDisplayed = true;
            facebookDisplayed = true;
        }
        if (user && user.youtubeAccounts && user.youtubeAccounts.length > 0) {
            accountDisplayed = true;
            youtubeDisplayed = true;
        }
        return (
            <Container
                component="main"
                maxWidth="md"
                sx={{
                    width: 1,
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "",
                    paddingLeft: "",
                }}>
                {instagramDisplayed && renderAccountsSection(Localization.socialMedias.instagram, instagramLogo, ()=>{}, "instagram", user.instagramAccounts)}
                {tiktokDisplayed && renderAccountsSection(Localization.socialMedias.tiktok, tiktokLogo, ()=>{}, "tiktok", user.tiktokAccounts)}
                {facebookDisplayed && renderAccountsSection(Localization.socialMedias.facebook, facebookLogo, ()=>{}, "facebook", user.facebookAccounts)}
                {youtubeDisplayed && renderAccountsSection(Localization.socialMedias.youtube, youtubeLogo, ()=>{}, "youtube", user.youtubeAccounts)}
                {!accountDisplayed && (
                    <Box
                        sx={{
                            width: 1,
                            display: "flex",
                            alignItems: "center",
                            marginTop: "2rem",
                            paddingLeft: "1rem",
                        }}>
                        <img style={{ width: "1rem", height: "1rem" }} src={instagramLogo} alt={"Instagram"} />
                        <Typography>{Localization.accountOverview.noinfoyet}</Typography>
                    </Box>
                )}

                </Container>
                );
    };

    const renderAccountsSection = (title, icon, collabHandler, themePrefix, accounts) => {
        return (
            <Box
                sx={{
                    width: 1,
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "2rem",
                }}>
                <Box
                    sx={{
                        width: 1,
                        display: "flex",
                        alignItems: "center",
                        marginTop: "2rem",
                    }}>
                    <img style={{ width: "1rem", height: "1rem" }} src={icon} alt={"Instagram"} />
                    <Typography
                        sx={{
                            color: "primary.main",
                            paddingLeft: "0.25rem",
                        }}
                        variant="section-title">
                        {title}
                    </Typography>
                    <FilledButton text={Localization.accountOverview.startcollab} colored></FilledButton>
                </Box>
                <Box
                    sx={{
                        width: 1,
                        display: "flex",
                        alignItems: "center",
                        marginTop: "0.5rem",
                    }}>
                    {accounts.map((account) => {
                        return (
                            <LightCard
                                lightColor={`${themePrefix}.main`}
                                darkColor={`${themePrefix}.darkk`}
                                textColor={`${themePrefix}.darkk`}
                                title={Localization.accountOverview.totalfollowers}
                                text={account.Followers}></LightCard>
                        );
                    })}
                </Box>
            </Box>
        );
    };

    return (
        <Box>
            <Header />
            <Container
                component="main"
                maxWidth="false"
                sx={{ paddingLeft: "0 !important", paddingRight: "0 !important" }}>
                <CssBaseline />
                <Backdrop sx={{ color: "#fff", zIndex: "zIndex.loading" }} open={stats.fetchingStats}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box
                    sx={{
                        paddingTop: 8,
                        paddingBottom: 4,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        bgcolor: "primary.main",
                        width: 1,
                        overflow: "auto",
                    }}>
                    <Box
                        sx={{
                            display: "flex",
                            //flexDirection: { xs: "column", sm: "row" },
                            alignItems: "center",
                        }}>
                        {/* AVATAR SECTION */}
                        <Box
                            sx={{
                                position: 'relative',
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                flex: 3,
                                alignSelf: 'flex-start'
                            }}>
                            <Avatar
                                alt={user && user.username}
                                src={user && user.preSignedProfileImageURL}
                                sx={{
                                    position: "absolute",
                                    right: 0,
                                    top: 0,
                                    width: { xs: "14rem", sm: "16rem" },
                                    height: { xs: "14rem", sm: "16rem" },
                                    border: "0.25rem solid #000",
                                }}
                            />
                        </Box>
                        {/* BIO SECTION */}
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                paddingLeft: "1rem",
                                paddingRight: "1rem",
                                flex: 5
                            }}>
                            <Typography textAlign="center" variant="fancy-outline" sx={{fontSize: "1.75rem"}}>
                                {user && user.Username}
                            </Typography>

                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    color: "white",
                                }}>
                                <FilledButton text={Localization.accountOverview.mediakit} sx={{fontSize: '0.8rem'}}></FilledButton>

                                <Typography textAlign="center" variant="footnote">
                                    {Localization.accountOverview.managedby}&nbsp;
                                </Typography>
                                <Typography textAlign="center" variant="footnote" sx={{ textDecoration: "underline" }}>
                                    Tiddle.io
                                </Typography>
                            </Box>

                            <Card
                                variant="light-card"
                                sx={{
                                    bgcolor: "primary.light",
                                }}>
                                <CardContent sx={{ display: "flex", flexDirection: "column" }}>
                                    <Typography variant="section-title">
                                        {Localization.accountOverview.aboutme}
                                    </Typography>
                                    <Typography variant="footnote" sx={{textAlign: 'left'}}>
                                        {user && user.MonthlyDescription}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Box>
                    </Box>

                    {/* ACTION SECTION */}
                    <Box sx={{ display: "flex", marginY: '1rem'}}>
                        <OutlinedButton minWidth text={Localization.accountOverview.contactme} />
                        <FilledButton minWidth text={Localization.accountOverview.hireme} />
                    </Box>

                    
                    <Container
                        component="content"
                        maxWidth="md"
                        sx={{ paddingLeft: "0 !important", paddingRight: "0 !important" }}>

                        {/* OVERALL SECTION */}
                        <Typography sx={{ width: 1, paddingLeft: "1rem", marginTop: "2rem" }} variant="section-title">
                            {Localization.accountOverview.overall}
                        </Typography>
                        {renderOverall()}
                    </Container>
                </Box>

                {/* SOCIAL SECTION */}
                {renderSocials()}
            </Container>
        </Box>
    );
};

export default AccountOverview;
