export const userConstants = {
  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

  LOGOUT: "USERS_LOGOUT",

  GET_REQUEST: "USERS_GET_REQUEST",
  GET_SUCCESS: "USERS_GET_SUCCESS",
  GET_FAILURE: "USERS_GET_FAILURE",

  CONNECT_INSTAGRAM_REQUEST: "USERS_CONNECT_INSTAGRAM_REQUEST",
  CONNECT_INSTAGRAM_SUCCESS: "USERS_CONNECT_INSTAGRAM_SUCCESS",
  CONNECT_INSTAGRAM_FAILURE: "USERS_CONNECT_INSTAGRAM_FAILURE",

  LOGOUT_FACEBOOK_REQUEST: "USERS_LOGOUT_FACEBOOK_REQUEST",
  LOGOUT_FACEBOOK_SUCCESS: "USERS_LOGOUT_FACEBOOK_SUCCESS",
  LOGOUT_FACEBOOK_FAILURE: "USERS_LOGOUT_FACEBOOK_FAILURE",
};
