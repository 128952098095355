import axios from 'axios';
import { ThemeProvider } from '@mui/material/styles';
import './App.scss';
import Router from "./routers/CoreRouter";
import ftTheme from './utils/theme';

// Facebook page https://developers.facebook.com/docs/platforminsights/page
// Engagement https://developers.facebook.com/docs/graph-api/reference/engagement/
// Instagram https://developers.facebook.com/docs/instagram-api/guides/business-discovery
// Tiktok https://preettheman.medium.com/how-to-pull-tiktok-following-count-from-any-user-using-python-3ffd0041215e
// Youtube https://developers.google.com/youtube/v3/docs/channels/list?apix_params=%7B%22part%22%3A%5B%22snippet%2CcontentDetails%2Cstatistics%22%5D%2C%22forUsername%22%3A%22GoogleDevelopers%22%7D


function App() {
  let token = localStorage.getItem('token');
  if (token != null) {
    axios.defaults.headers.common['Authorization'] = token;
  }

  return (
    <div className="App">
      <ThemeProvider theme={ftTheme}>
        <Router />
      </ThemeProvider >
    </div>
  );
}

export default App;
