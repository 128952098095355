import { userConstants } from "../constants";
import { userServices } from "../services/";

export const userActions = {
  login,
  logout,
  getUser,
  connectInstagram,
  connectTiktok,
  logoutFacebook,
};

function login(username, password) {
  return (dispatch) => {
    dispatch(request({ username }));

    userServices.login(username, password).then(
      (user) => {
        dispatch(success(user));
      },
      (error) => {
        if (error.response) {
          dispatch(failure(error.response.data.message.toString()));
        } else {
          dispatch(
            failure(
              "Unable to log you in at this moment. Please try again later."
            )
          );
          //dispatch(failure(error.toString()));
        }
      }
    );
  };

  function request(username) {
    return { type: userConstants.LOGIN_REQUEST, username };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function logout() {
  userServices.logout();
  return { type: userConstants.LOGOUT };
}

function getUser(username) {
  return (dispatch) => {
    dispatch(request({ username }));

    userServices.getUser(username).then(
      (user) => {
        dispatch(success(user));
      },
      (error) => {
        if (error.response) {
          dispatch(failure(error.response.data.message.toString()));
        } else {
          dispatch(
            failure(
              "Unable to fetch user in at this moment. Please try again later."
            )
          );
        }
      }
    );
  };

  function request(username) {
    return { type: userConstants.GET_REQUEST, username };
  }
  function success(user) {
    return { type: userConstants.GET_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.GET_FAILURE, error };
  }
}

function connectInstagram(username, pageId, accessToken) {
  return (dispatch) => {
    dispatch(request({}));

    userServices.connectInstagram(username, pageId, accessToken).then(
      (instagramAccount) => {
        dispatch(success(instagramAccount));
      },
      (error) => {
        if (error.response) {
          dispatch(failure(error.response.data.message.toString()));
        } else {
          dispatch(
            failure(
              "Unable to logout facebook in at this moment. Please try again later."
            )
          );
        }
      }
    );
  };

  function request() {
    return { type: userConstants.CONNECT_INSTAGRAM_REQUEST };
  }
  function success(instagramAccount) {
    return { type: userConstants.CONNECT_INSTAGRAM_SUCCESS, instagramAccount };
  }
  function failure(error) {
    return { type: userConstants.CONNECT_INSTAGRAM_FAILURE, error };
  }
}

function connectTiktok(authorizationCode) {
  return (dispatch) => {
    dispatch(request({}));

    userServices.connectTiktok(authorizationCode).then(
      (tiktokAccount) => {
        dispatch(success(tiktokAccount));
      },
      (error) => {
        if (error.response) {
          dispatch(failure(error.response.data.message.toString()));
        } else {
          dispatch(
            failure(
              "Unable to connect to tiktok in at this moment. Please try again later."
            )
          );
        }
      }
    );
  };

  function request() {
    return { type: userConstants.CONNECT_TIKTOK_REQUEST };
  }
  function success(instagramAccount) {
    return { type: userConstants.CONNECT_TIKTOK_SUCCESS, instagramAccount };
  }
  function failure(error) {
    return { type: userConstants.CONNECT_TIKTOK_FAILURE, error };
  }
}

function logoutFacebook() {
  return (dispatch) => {
    dispatch(request({}));

    userServices.logoutFacebook().then(
      (user) => {
        dispatch(success());
      },
      (error) => {
        if (error.response) {
          dispatch(failure(error.response.data.message.toString()));
        } else {
          dispatch(
            failure(
              "Unable to logout facebook in at this moment. Please try again later."
            )
          );
        }
      }
    );
  };

  function request() {
    return { type: userConstants.LOGOUT_FACEBOOK_REQUEST };
  }
  function success() {
    return { type: userConstants.LOGOUT_FACEBOOK_SUCCESS };
  }
  function failure(error) {
    return { type: userConstants.LOGOUT_FACEBOOK_FAILURE, error };
  }
}
