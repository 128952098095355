import { combineReducers } from "redux";

import { users } from "./userReducer";
import { admin } from "./adminReducer";
import { stats } from "./statsReducer";
import { facebook } from "./facebookReducer";
import { tiktok } from "./tiktokReducer";

export default combineReducers({
    users,
    admin,
    stats,
    facebook,
    tiktok,
});
