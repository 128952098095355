import { adminConstants } from '../constants';

const initialState = {
    fetchingReport: false,
    report: null,
    reportFetchingErrorMessage: null
};

export function admin(state = initialState, action) {
    switch (action.type) {
        case adminConstants.REPORT_REQUEST:
            return {
                ...state,
                fetchingReport: true,
                report: null,
                reportFetchingErrorMessage: null
            };
        case adminConstants.REPORT_SUCCESS:
            return {
                ...state,
                fetchingReport: false,
                report: action.report
            };
        case adminConstants.REPORT_FAILURE:
            return {
                ...state,
                fetchingReport: false,
                reportFetchingErrorMessage: action.error
            };
        default:
            return state
    }
}