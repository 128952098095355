import { facebookConstants } from "../constants";

const initialState = {
  fetchingPages: false,
  facebookPages: null,
  pagesFetchingErrorMessage: null,

  fetchingInstagramPageID: false,
  instagramPageID: null,
  instagramPageIDFetchingErrorMessage: null,

  fetchingLongLivedToken: false,
  longLivedToken: null,
  longLivedTokenFetchingErrorMessage: null,
};

export function facebook(state = initialState, action) {
  switch (action.type) {
    case facebookConstants.FETCH_PAGES_REQUEST:
      return {
        ...state,
        fetchingPages: true,
        facebookPages: null,
        pagesFetchingErrorMessage: null,
      };
    case facebookConstants.FETCH_PAGES_SUCCESS:
      return {
        ...state,
        fetchingPages: false,
        facebookPages: action.pages,
      };
    case facebookConstants.FETCH_PAGES_FAILURE:
      return {
        ...state,
        fetchingPages: false,
        pagesFetchingErrorMessage: action.error,
      };
    case facebookConstants.FETCH_INSTA_PAGE_ID_REQUEST:
      return {
        ...state,
        fetchingInstagramPageID: true,
        instagramPageID: null,
        instagramPageIDFetchingErrorMessage: null,
      };
    case facebookConstants.FETCH_INSTA_PAGE_ID_SUCCESS:
      return {
        ...state,
        fetchingInstagramPageID: false,
        instagramPageID: action.instagramPageID,
      };
    case facebookConstants.FETCH_INSTA_PAGE_ID_FAILURE:
      return {
        ...state,
        fetchingInstagramPageID: false,
        instagramPageIDFetchingErrorMessage: action.error,
      };
    case facebookConstants.FETCH_LONG_LIVED_TOKEN_REQUEST:
      return {
        ...state,
        fetchingLongLivedToken: true,
        longLivedToken: null,
        longLivedTokenFetchingErrorMessage: null,
      };
    case facebookConstants.FETCH_LONG_LIVED_TOKEN_SUCCESS:
      return {
        ...state,
        fetchingLongLivedToken: false,
        longLivedToken: action.longLivedAccessToken,
      };
    case facebookConstants.FETCH_LONG_LIVED_TOKEN_FAILURE:
      return {
        ...state,
        fetchingLongLivedToken: false,
        longLivedTokenFetchingErrorMessage: action.error,
      };
    default:
      return state;
  }
}
