import { statsConstants } from "../constants";
import { statsServices } from "../services/";

export const statsActions = {
    fetchStatistics,
    fetchBasicPageInfos,
};

function fetchStatistics() {
    return (dispatch, getState) => {
        dispatch(request());

        statsServices.fetchStatistics(getState().users.facebookUserId, getState().users.facebookUserAccessToken).then(
            (stats) => {
                dispatch(success(stats));
            },
            (error) => {
                if (error.response && error.response.data && error.response.data.message) {
                    dispatch(failure(error.response.data.message.toString()));
                } else {
                    dispatch(failure("Unable to fetch stats at this moment. Please try again later."));
                }
            }
        );
    };

    function request() {
        return { type: statsConstants.FETCH_REQUEST };
    }
    function success(stats) {
        return { type: statsConstants.FETCH_SUCCESS, stats };
    }
    function failure(error) {
        return { type: statsConstants.FETCH_FAILURE, error };
    }
}

function fetchBasicPageInfos(username) {
    return (dispatch, getState) => {
        dispatch(request());

        statsServices.fetchBasicPageInfos(username).then(
            (users) => {
                dispatch(success(users));
            },
            (error) => {
                if (error.response && error.response.data && error.response.data.message) {
                    dispatch(failure(error.response.data.message.toString()));
                } else {
                    dispatch(failure("Unable to fetch basic infos at this moment. Please try again later."));
                }
            }
        );
    };

    function request() {
        return { type: statsConstants.FETCH_BASIC_INFOS_REQUEST };
    }
    function success(users) {
        return { type: statsConstants.FETCH_BASIC_INFOS_SUCCESS, users };
    }
    function failure(error) {
        return { type: statsConstants.FETCH_BASIC_INFOS_FAILURE, error };
    }
}
