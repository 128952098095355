// ES6 module syntax
import LocalizedStrings from "react-localization";

let strings = new LocalizedStrings({
    en: {
        header: {
            profile: "Profile",
            logout: "Logout",
            login: "Login",
            entries: "Entries",
            admin: "Administration",
        },
        socialMedias: {
            instagram: "Instagram",
            facebook: "Facebook",
            tiktok: "Tiktok",
            youtube: "Youtube",
        },
        home: {
            welcome: "Welcome to FanMetrics. Your one-stop hub for anything content creation related!",
            start: "Start by creating a Fanstories account through ",
            fanstories: "Fanstories.com",
        },
        accountOverview: {
            mediakit: "My media kit",
            managedby: "Managed by ",
            aboutme: "About me",
            contactme: "Contact me",
            hireme: "Hire me",
            overall: "Overall",
            noinfoyet: "No information yet",
            totalreach: "Total available reach",
            genderdistribution: "Gender distribution",
            men: "Men",
            women: "Women",
            startcollab: "Start collab",
            totalfollowers: "Total followers",
            engagement: "Engagement",
            poweredby: "Powered by",
        },
        profile: {
            connectedaccounts: "Connected accounts",
            noconnectedaccounts: "You currently have no connected accounts.",
            connectaccounts: "Connect social account",
        },
        connectfacebook: {
            connectfacebookrequired: "Facebook redirection required",
            businessProfileRequired:
                "In order to connect your Instagram successfully, your account must be connected to a Facebook page and be a business profile. When connecting your Instagram, please grant all Facebook permissions to be connected successfully.",
        },
        connectinstagram: {
            nopages: "No business pages found.",
            selectPage: "Select the desired facebook page.",
        },
    },
    fr: {
        header: {
            profile: "Profil",
            logout: "Déconnexion",
            entries: "Entrées",
            admin: "Administration",
        },
    },
});

export default strings;
