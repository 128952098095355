import { createTheme } from "@mui/material/styles";

export default createTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: "#54339d",
            light: "#694ca8",
            dark: "#35235f",
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            main: "#ff6197",
            dark: "#fad4e1",
        },
        instagram: {
            main: "#fad4e1",
            dark: "#ff6197",
        },
        facebook: {
            main: "#3465bf",
            dark: "#e2eaf7",
        },
        tiktok: {
            main: "#3465bf",
            dark: "#e2eaf7",
        },
        white: {
            main: "#ffffff",
            dark: "#ffffff",
        },
        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
    },
    typography: {
        fontFamily: ["Isidora Sans Bold"],
    },
    zIndex: {
        loading: 1000,
    },
    components: {
        MuiTypography: {
            variants: [
                {
                    props: { variant: "underlined" },
                    style: {
                        textDecoration: "underline",
                    },
                },
                {
                    props: { variant: "footnote" },
                    style: {
                        fontFamily: "Isidora Sans",
                        fontSize: "0.8rem",
                    },
                },
                {
                    props: { variant: "section-title" },
                    style: {
                        color: "white",
                        fontFamily: "Isidora Sans Bold",
                        fontSize: "1.2rem",
                        textAlign: "left",
                    },
                },
                {
                    props: { variant: "section-text" },
                    style: {
                        color: "white",
                        fontFamily: "Isidora Sans",
                        fontSize: "1rem",
                        textAlign: "left",
                        lineHeight: "1.1rem",
                    },
                },
                {
                    props: { variant: "fancy-outline" },
                    style: {
                        "fontFamily": "Isidora Sans Bold",
                        "fontSize": "2.25rem",
                        "background": "-webkit-linear-gradient(-86deg, #EEF85B 5%, #7AEC8D 53%, #09E5C3 91%)",
                        "-webkit-background-clip": "text",
                        "-webkit-text-stroke": "4px transparent",
                        "textShadow": "0px 4px  8px #222222",
                        "color": "white",
                    },
                },
                {
                    props: { variant: "sm-fancy-outline" },
                    style: {
                        "fontFamily": "Isidora Sans Bold",
                        "fontSize": "2.25rem",
                        "background": "-webkit-linear-gradient(-86deg, #EEF85B 5%, #7AEC8D 53%, #09E5C3 91%)",
                        "-webkit-background-clip": "text",
                        "-webkit-text-stroke": "4px transparent",
                        "textShadow": "0px 4px  8px #222222",
                        "color": "white",
                    },
                },
            ],
        },
        MuiCard: {
            variants: [
                {
                    props: { variant: "light-card" },
                    style: {
                        borderRadius: "0.5rem",
                        margin: "0.5rem",
                        color: "white",
                    },
                },
            ],
        },
    },
});
