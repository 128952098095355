import { userConstants, statsConstants } from "../constants";
var moment = require("moment");

let user = JSON.parse(localStorage.getItem("user"));
const initialState = {
    user: user,
    loggingIn: false,
    loggedIn: user !== undefined && user !== null,
    refreshingToken: false,
    errorMessage: null,

    fetchingUser: false,
    fetchedUser: null,
    fetchingUserErrorMessage: null,

    connectingInstagram: false,
    instagramAccount: null,
    connectingInstagramErrorMessage: null,

    facebookUserId: null,
    facebookUserAccessToken: null,
};

export function users(state = initialState, action) {
    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
            return {
                ...state,
                loggedIn: false,
                loggingIn: true,
                user: null,
                errorMessage: null,
            };
        case userConstants.LOGIN_SUCCESS:
            return {
                ...state,
                loggedIn: true,
                loggingIn: false,
                user: action.user,
                errorMessage: null,
            };
        case userConstants.LOGIN_FAILURE:
            return {
                ...state,
                loggedIn: false,
                loggingIn: false,
                errorMessage: action.error,
            };
        case userConstants.GET_REQUEST:
            return {
                ...state,
                fetchingUser: true,
                fetchedUser: null,
                fetchingUserErrorMessage: null,
            };
        case userConstants.GET_SUCCESS:
            let loggedUser = state.user;
            if (loggedUser && loggedUser.username === action.user.username) {
                loggedUser = action.user;
            }
            return {
                ...state,
                user: loggedUser,
                fetchingUser: false,
                fetchedUser: action.user,
            };
        case userConstants.GET_FAILURE:
            return {
                ...state,
                fetchingUser: false,
                fetchingUserErrorMessage: action.error,
            };
        case userConstants.LOGOUT:
            return {
                ...state,
                user: null,
                loggedIn: false,
                loggingIn: false,
            };
        case userConstants.LOGOUT_FACEBOOK_REQUEST:
            return {
                ...state,
                loggingIn: true,
            };
        case userConstants.LOGOUT_FACEBOOK_SUCCESS:
            return {
                ...state,
                loggedIn: false,
                facebookUserAccessToken: null,
                facebookUserId: null,
            };
        case userConstants.CONNECT_INSTAGRAM_REQUEST:
            return {
                ...state,
                connectingInstagram: true,
                instagramAccount: null,
                connectingInstagramErrorMessage: null,
            };
        case userConstants.CONNECT_INSTAGRAM_SUCCESS: {
            let user = state.user;
            if (user.instagramAccounts == null) {
                user.instagramAccounts = [];
            }
            user.instagramAccounts.push(action.instagramAccount);
            return {
                ...state,
                user: user,
                connectingInstagram: false,
                instagramAccount: action.instagramAccount,
            };
        }
        case userConstants.CONNECT_INSTAGRAM_FAILURE:
            return {
                ...state,
                connectingInstagram: false,
                connectingInstagramErrorMessage: action.error,
            };
        case userConstants.CONNECT_TIKTOK_REQUEST:
            return {
                ...state,
                connectingInstagram: true,
                instagramAccount: null,
                connectingInstagramErrorMessage: null,
            };
        case userConstants.CONNECT_TIKTOK_SUCCESS: {
            let user = state.user;
            if (user.instagramAccounts == null) {
                user.instagramAccounts = [];
            }
            user.instagramAccounts.push(action.instagramAccount);
            return {
                ...state,
                user: user,
                connectingInstagram: false,
                instagramAccount: action.instagramAccount,
            };
        }
        case userConstants.CONNECT_TIKTOK_FAILURE:
            return {
                ...state,
                connectingInstagram: false,
                connectingInstagramErrorMessage: action.error,
            };
        case statsConstants.FETCH_BASIC_INFOS_SUCCESS: {
            let user = state.user;
            if (user != null && user != undefined) {
                let fetchedUsers = action.users;
                for (const fetchedUser of fetchedUsers) {
                    if (fetchedUser.HostID == user.HostID) {
                        user.instagramAccounts = fetchedUser.instagramAccounts;
                    }
                }
            }
            return {
                ...state,
                user: user,
            };
        }
        default:
            return state;
    }
}
