import axios from "axios";
import { appConstants } from "../../utils/config";

export const statsServices = {
    fetchStatistics,
    fetchBasicPageInfos,
};

async function fetchStatistics(facebookUserId, facebookAccessToken) {
    /*let facebokPages = await getFacebookPages(facebookAccessToken);
    if (facebokPages && facebokPages.length > 0) {
        let fanstoriesPage = facebokPages.filter((page) => {
            return page.name == "Fanstories";
        })[0];
        let instagramPageID = await getInstagramPageID(facebookAccessToken, fanstoriesPage.id);

        //let stats = await queryInstagramPageInsights(facebookAccessToken, instagramPageID);
        let followersCount = await getInstagramPageFollowersCount(facebookAccessToken, instagramPageID);
    }*/
}

function fetchBasicPageInfos(username) {
    return new Promise(function (resolve, reject) {
        let url = appConstants.BACKEND_ADDRESS + appConstants.BASIC_PAGE_ROUTE;
        if (username != null && username != undefined) {
            url = url + "?username=" + username;
        }
        axios
            .get(url)
            .then((response) => {
                if (response.error) {
                    // Problem occured
                    reject(response.error);
                } else {
                    if (response.status === 200) {
                        var hosts = response.data.response.hosts;
                        resolve(hosts);
                    } else if (response.status === 403) {
                        // password mismatch
                        reject(response.data.message);
                    } else if (response.status === 400) {
                        // password mismatch
                        reject(response.data.message);
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
                reject(error);
            });
    });
}
