import { statsConstants } from "../constants";

const initialState = {
    fetchingStats: false,
    stats: {},
    statsFetchingErrorMessage: null,
};

export function stats(state = initialState, action) {
    switch (action.type) {
        case statsConstants.FETCH_REQUEST:
            return {
                ...state,
                fetchingStats: true,
                stats: null,
                statsFetchingErrorMessage: null,
            };
        case statsConstants.FETCH_SUCCESS:
            return {
                ...state,
                fetchingStats: false,
                stats: action.stats,
            };
        case statsConstants.FETCH_FAILURE:
            return {
                ...state,
                fetchingStats: false,
                statsFetchingErrorMessage: action.error,
            };
        case statsConstants.FETCH_BASIC_INFOS_REQUEST:
            return {
                ...state,
                fetchingStats: true,
                statsFetchingErrorMessage: null,
            };
        case statsConstants.FETCH_BASIC_INFOS_SUCCESS:
            let fetchedUsers = action.users;
            for (const fetchedUser of fetchedUsers) {
                stats[fetchedUser.Username] = fetchedUser;
            }
            return {
                ...state,
                fetchingStats: false,
                stats: stats,
            };
        case statsConstants.FETCH_BASIC_INFOS_FAILURE:
            return {
                ...state,
                fetchingStats: false,
                statsFetchingErrorMessage: action.error,
            };
        default:
            return state;
    }
}
