import { tiktokConstants } from "../constants";

const initialState = {
    fetchingUser: false,
    fetchedUser: null,
    fetchingUserErrorMessage: null,
};

export function tiktok(state = initialState, action) {
    switch (action.type) {
        case tiktokConstants.FETCH_USER_REQUEST:
            return {
                ...state,
                fetchingUser: true,
                fetchedUser: null,
                fetchingUserErrorMessage: null,
            };
        case tiktokConstants.FETCH_USER_SUCCESS:
            return {
                ...state,
                fetchingUser: false,
                fetchedUser: action.users,
            };
        case tiktokConstants.FETCH_USER_FAILURE:
            return {
                ...state,
                fetchingUser: false,
                fetchingUserErrorMessage: action.error,
            };
        default:
            return state;
    }
}
