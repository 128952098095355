import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Localization from "../../utils/localization";

import Header from "../../components/Header";

import { userActions, facebookActions } from "../../redux/actions/";

const ConnectInstagram = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const user = useSelector((state) => state.users.user);
    const facebookStore = useSelector((state) => state.facebook);
    const connectingInstagram = useSelector((state) => state.users.connectingInstagram);
    const connectedInstagramAccount = useSelector((state) => state.users.instagramAccount);
    //const [connectingInstagram, setConnectingInstagram] = React.useState(false);
    const [initialScroll, setInitialScroll] = React.useState(false);
    const [selectedFacebookPage, setSelectedFacebookPage] = React.useState(null);

    // Fetch Facebook Pages
    useEffect(() => {
        if (location && location.state && location.state.facebookToken) {
            let facebookAccessToken = location.state.facebookToken;
            dispatch(facebookActions.getFacebookPages(facebookAccessToken));
        } else {
            navigate("/profile");
        }
    }, []);

    // User selected a facebook page. Fetch associated Instagram page
    useEffect(() => {
        if (!facebookStore.fetchingInstagramPageID && facebookStore.instagramPageID && selectedFacebookPage) {
            // Now create an account connection
            let facebookAccessToken = location.state.facebookToken;
            dispatch(
                userActions.connectInstagram(
                    selectedFacebookPage.name,
                    facebookStore.instagramPageID,
                    facebookAccessToken
                )
            );
        }
    }, [facebookStore.fetchingInstagramPageID]);

    // User connected an instagram page
    useEffect(() => {
        if (!connectingInstagram && connectedInstagramAccount) {
            // Now create an account connection

            navigate("/profile");
        }
    }, [connectingInstagram]);

    const handleSelectPage = (facebookPageInfos) => {
        let facebookAccessToken = location.state.facebookToken;
        setSelectedFacebookPage(facebookPageInfos);
        dispatch(facebookActions.getInstagramPageID(facebookAccessToken, facebookPageInfos.id));
    };

    const renderPages = () => {
        if (facebookStore.fetchingPages) {
            return <CircularProgress color="inherit" />;
        } else if (facebookStore.facebookPages && facebookStore.facebookPages.length > 0) {
            return (
                <Box
                    maxWidth="sm"
                    sx={{
                        width: 1,
                        marginLeft: "auto",
                        marginRight: "auto",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}>
                    <Typography
                        variant="section-title"
                        sx={{
                            color: "black",
                            width: 1,
                            padding: "1rem",
                        }}>
                        {Localization.connectinstagram.selectPage}
                    </Typography>
                    {facebookStore.facebookPages.map((facebookPage, index) => {
                        return (
                            <Typography
                                key={index}
                                variant="section-text"
                                sx={{
                                    color: "black",
                                    width: 1,
                                    padding: "0.5rem",
                                }}
                                onClick={() => {
                                    handleSelectPage(facebookPage);
                                }}>
                                {facebookPage.name}
                            </Typography>
                        );
                    })}
                </Box>
            );
        } else {
            <Typography
                variant="section-text"
                sx={{
                    color: "#000",
                    width: 1,
                }}>
                {Localization.connectinstagram.nopages}
            </Typography>;
        }
    };

    return (
        <Box>
            <Header />
            <Container
                component="main"
                maxWidth="md"
                sx={{
                    paddingLeft: "0 !important",
                    paddingRight: "0 !important",
                }}>
                <CssBaseline />
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.loading + 1 }}
                    open={facebookStore.fetchingPages || facebookStore.fetchingInstagramPageID || connectingInstagram}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box
                    sx={{
                        width: 1,
                        bgcolor: "primary.main",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginBottom: "1rem",
                    }}>
                    <Box
                        maxWidth="sm"
                        sx={{
                            width: 1,
                            bgcolor: "primary.main",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}>
                        <Avatar
                            alt={user && user.username}
                            src={user && user.preSignedProfileImageURL}
                            sx={{
                                width: { xs: "8rem", sm: "8rem" },
                                height: { xs: "8rem", sm: "8rem" },
                                border: "0.25rem solid #000",
                            }}
                        />
                        <Typography textAlign="center" variant="fancy-outline">
                            {user && user.Username}
                        </Typography>
                    </Box>
                </Box>
                <Box
                    maxWidth="sm"
                    sx={{
                        width: 1,
                        marginLeft: "auto",
                        marginRight: "auto",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}>
                    {renderPages()}
                </Box>
            </Container>
        </Box>
    );
};
export default ConnectInstagram;
