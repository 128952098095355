import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import PersonIcon from "@mui/icons-material/Person";
import Typography from "@mui/material/Typography";

const LightCard = (props) => {
  return (
    <Card
      variant="light-card"
      sx={{
        bgcolor: props.lightColor,
        width: "12rem",
      }}
    >
      <CardContent
        sx={{ display: "flex", flexDirection: "column", paddingTop: "8px" }}
      >
        <Box sx={{ display: "flex", alignItems: "start" }}>
          <Typography
            variant="section-text"
            sx={{ width: "12rem", color: props.textColor }}
          >
            {props.title}
          </Typography>
          <PersonIcon sx={{ color: props.darkColor }} />
        </Box>

        <Typography
          variant="fancy-outline"
          sx={{ textAlign: "left", marginTop: "1rem" }}
        >
          {props.text}
        </Typography>
      </CardContent>
    </Card>
  );
};
export default LightCard;
