import React, { Component } from "react";
import { connect } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Localization from "../../utils/localization";

import Header from "../../components/Header";
import FilledButton from "../../components/FilledButton";
import OutlinedButton from "../../components/OutlinedButton";
import LightCard from "../../components/LightCard";
import { userActions, statsActions } from "../../redux/actions/";

import instagramLogo from "../../assets/images/instagram_icon_600.png";

class Home extends Component {
    render() {
        let user = this.props.user;
        return (
            <Box sx={{ width: 1, height: 1, bgcolor: "primary.main" }}>
                <Header />
                <Container
                    component="main"
                    maxWidth="md"
                    sx={{ paddingLeft: "0 !important", paddingRight: "0 !important" }}>
                    <CssBaseline />
                    <Backdrop sx={{ color: "#fff", zIndex: "zIndex.loading" }} open={this.props.fetchingStats}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Box
                        sx={{
                            paddingTop: 8,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            width: 1,
                            overflow: "auto",
                            paddingBottom: "2rem",
                        }}>
                        <Typography sx={{ width: 1, paddingLeft: "1rem", marginTop: "2rem" }} variant="section-title">
                            {Localization.home.welcome}
                        </Typography>

                        <Typography sx={{ width: 1, paddingLeft: "1rem", marginTop: "2rem" }} variant="section-text">
                            {Localization.home.start}
                        </Typography>

                        <Typography
                            onClick={() => {
                                window.location.href = "https://www.fanstories.com/signup/";
                            }}
                            sx={{ width: 1, paddingLeft: "1rem", marginTop: "auto" }}
                            variant="fancy-outline">
                            {Localization.home.fanstories}
                        </Typography>
                    </Box>
                </Container>
            </Box>
        );
    }
}

function mapStateToProps(state) {
    let props = {};

    if (state.users) {
        props.user = state.users.user;
        props.loggedIn = state.users.loggedIn;
        props.facebookUserId = state.users.facebookUserId;
        props.facebookUserAccessToken = state.users.facebookUserAccessToken;
    }

    if (state.stats) {
        props.fetchingStats = state.stats.fetchingStats;
        props.stats = state.stats.stats;
    }

    return props;
}

const connectedHome = connect(mapStateToProps)(Home);
export default connectedHome;
