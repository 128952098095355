const PROD = false;

export const appConstants = {
  FANSTATS_BACKEND_ADDRESS: PROD
    ? "https://ag24us9ht5.execute-api.us-east-1.amazonaws.com/dev/"
    : "https://ag24us9ht5.execute-api.us-east-1.amazonaws.com/dev/",
  FANSTORIES_BACKEND_ADDRESS: PROD
    ? "https://qz45bg5ykk.execute-api.us-east-1.amazonaws.com/prod/"
    : "https://lnjoci95jg.execute-api.us-east-1.amazonaws.com/dev/",
  BACKEND_ADDRESS: PROD
    ? "https://ag24us9ht5.execute-api.us-east-1.amazonaws.com/prod/"
    : "https://ag24us9ht5.execute-api.us-east-1.amazonaws.com/dev/",
  ADMIN_ROUTE: "admin/",
  HOSTS_ROUTE: "hosts/",
  USERS_ROUTE: "users/",
  LOGIN_ROUTE: "login/",
  BASIC_PAGE_ROUTE: "basic_page/",
  INSTAGRAM_ROUTE: "instagram/",
  TIKTOK_ROUTE: "tiktok/",

  CONNECT_METHOD: "connect/",
  DISCONNECT_METHOD: "disconnect/",
  TOKEN_METHOD: "token/",

  //FACEBOOK_APP_ID: "371620631447786",
  //FACEBOOK_APP_SECRET: "07065ef63eb2f266a41267f6ea4fdbd4",
  FACEBOOK_APP_ID: "949361352450345",
  FACEBOOK_APP_SECRET: "b2cad937f58e403b83ed2a6aa46e3c73",

  APPLICATION_URL: PROD ? "fanstories.com" : "dev.fanstories.tv",
};
