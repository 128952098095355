import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Localization from "../../utils/localization";
import { appConstants } from "../../utils/config";

import FacebookLogin from "react-facebook-login";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxWidth: '80%',
  backgroundColor: "white",
  padding: "1rem",
  border: "0.5px solid #d4d4d4",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 4,
};

const ConnectFacebookModal = (props) => {
  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box style={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {Localization.connectfacebook.connectfacebookrequired}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {Localization.connectfacebook.businessProfileRequired}
        </Typography>
        <Box
          sx={{
            width: 1,
            display: "flex",
            justifyContent: "center",
            paddingTop: "1rem",
          }}
        >
          <FacebookLogin
            appId={appConstants.FACEBOOK_APP_ID}
            autoLoad={true}
            fields="email"
            scope="public_profile, pages_show_list, instagram_basic, instagram_manage_insights, business_management, read_insights"
            callback={props.handleFacebookResponse}
            icon="fa-facebook"
          />
        </Box>
      </Box>
    </Modal>
  );
};
export default ConnectFacebookModal;
