import { facebookConstants } from "../constants";
import { facebookServices } from "../services/";

export const facebookActions = {
  getFacebookPages,
  getInstagramPageID,
  getLongLivedAccessToken,
};

function getFacebookPages(facebookAccessToken) {
  return (dispatch, getState) => {
    dispatch(request());

    facebookServices.getFacebookPages(facebookAccessToken).then(
      (pages) => {
        dispatch(success(pages));
      },
      (error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(failure(error.response.data.message.toString()));
        } else {
          dispatch(
            failure(
              "Unable to fetch facebook at this moment. Please try again later."
            )
          );
        }
      }
    );
  };

  function request() {
    return { type: facebookConstants.FETCH_PAGES_REQUEST };
  }
  function success(pages) {
    return { type: facebookConstants.FETCH_PAGES_SUCCESS, pages };
  }
  function failure(error) {
    return { type: facebookConstants.FETCH_PAGES_FAILURE, error };
  }
}

function getInstagramPageID(facebookAccessToken, facebookPageID) {
  return (dispatch, getState) => {
    dispatch(request());

    facebookServices
      .getInstagramPageID(facebookAccessToken, facebookPageID)
      .then(
        (instagramPageID) => {
          dispatch(success(instagramPageID));
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(failure(error.response.data.message.toString()));
          } else {
            dispatch(
              failure(
                "Unable to fetch facebook at this moment. Please try again later."
              )
            );
          }
        }
      );
  };

  function request() {
    return { type: facebookConstants.FETCH_INSTA_PAGE_ID_REQUEST };
  }
  function success(instagramPageID) {
    return {
      type: facebookConstants.FETCH_INSTA_PAGE_ID_SUCCESS,
      instagramPageID,
    };
  }
  function failure(error) {
    return { type: facebookConstants.FETCH_INSTA_PAGE_ID_FAILURE, error };
  }
}

function getLongLivedAccessToken(facebookAccessToken) {
  return (dispatch, getState) => {
    dispatch(request());

    facebookServices.getLongLivedAccessToken(facebookAccessToken).then(
      (longLivedAccessToken) => {
        dispatch(success(longLivedAccessToken));
      },
      (error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(failure(error.response.data.message.toString()));
        } else {
          dispatch(
            failure(
              "Unable to fetch facebook at this moment. Please try again later."
            )
          );
        }
      }
    );
  };

  function request() {
    return { type: facebookConstants.FETCH_LONG_LIVED_TOKEN_REQUEST };
  }
  function success(longLivedAccessToken) {
    return {
      type: facebookConstants.FETCH_LONG_LIVED_TOKEN_SUCCESS,
      longLivedAccessToken,
    };
  }
  function failure(error) {
    return { type: facebookConstants.FETCH_LONG_LIVED_TOKEN_FAILURE, error };
  }
}
