export const facebookConstants = {
  FETCH_PAGES_REQUEST: "FACEBOOK_FETCH_PAGES_REQUEST",
  FETCH_PAGES_SUCCESS: "FACEBOOK_FETCH_PAGES_SUCCESS",
  FETCH_PAGES_FAILURE: "FACEBOOK_FETCH_PAGES_FAILURE",

  FETCH_INSTA_PAGE_ID_REQUEST: "FACEBOOK_FETCH_INSTA_PAGE_ID_REQUEST",
  FETCH_INSTA_PAGE_ID_SUCCESS: "FACEBOOK_FETCH_INSTA_PAGE_ID_SUCCESS",
  FETCH_INSTA_PAGE_ID_FAILURE: "FACEBOOK_FETCH_INSTA_PAGE_ID_FAILURE",

  FETCH_LONG_LIVED_TOKEN_REQUEST: "FACEBOOK_FETCH_INSTA_PAGE_ID_REQUEST",
  FETCH_LONG_LIVED_TOKEN_SUCCESS: "FACEBOOK_FETCH_INSTA_PAGE_ID_SUCCESS",
  FETCH_LONG_LIVED_TOKEN_FAILURE: "FACEBOOK_FETCH_INSTA_PAGE_ID_FAILURE",
};
