import React, { Component } from "react";
import { connect } from "react-redux";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { ThemeProvider } from "@mui/material/styles";
import Snackbar from "@mui/material/Snackbar"; // Implement stackable notifications with https://mui.com/components/snackbars/#notistack
import MuiAlert from "@mui/material/Alert";
import { Waypoint } from "react-waypoint";

import Header from "../../components/Header";
import { adminActions } from "../../redux/actions/";
import ftTheme from "../../utils/theme";

class Admin extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (!this.props.report) {
            //const { dispatch } = this.props;
            //dispatch(adminActions.fetchReport());
        }
    }

    componentDidUpdate(prevPros) {}

    handleCloseNotification = () => {
        this.setState({ errorMessage: null });
    };

    render() {
        return (
            <ThemeProvider theme={ftTheme}>
                <Header />
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.loading + 1 }} open={false}>
                        <CircularProgress color="inherit" />
                    </Backdrop>

                    <Snackbar
                        open={this.state.errorMessage !== null && this.state.errorMessage !== ""}
                        autoHideDuration={6000}
                        onClose={this.handleCloseNotification}>
                        <MuiAlert
                            elevation={6}
                            variant="filled"
                            onClose={this.handleCloseNotification}
                            severity="error"
                            sx={{ width: "100%" }}>
                            {this.state.errorMessage}
                        </MuiAlert>
                    </Snackbar>

                    <Box sx={{ marginTop: 8, display: "flex", flexDirection: "column", alignItems: "center" }}></Box>
                </Container>
            </ThemeProvider>
        );
    }
}

function mapStateToProps(state) {
    let props = {};

    /*if (state.admin) {
        props.fetchingReport = state.admin.fetchingReport;
        props.report = state.admin.report;
        props.fetchingReportErrorMessage = state.admin.fetchingReportErrorMessage;
    }*/

    return props;
}

const connectedAdmin = connect(mapStateToProps)(Admin);
export default connectedAdmin;
