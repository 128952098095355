import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from "axios";
import { appConstants } from "../utils/config";

export const useConnectTiktok = () => {
    const queryClient = useQueryClient();
    return useMutation(async (authorizationCode)=> {
        let response = await axios.post(appConstants.FANSTATS_BACKEND_ADDRESS + appConstants.TIKTOK_ROUTE + appConstants.CONNECT_METHOD, {
            authorizationCode: authorizationCode,
        });
    
        if (response.error) {
            // Problem occured, auto logout ... ?
            throw response.error;
        } else {
            if (response.status === 201) {
                // login succesful
                let tiktokConnectedAccount = response.data.response.tiktokAccount;
                return tiktokConnectedAccount;
            } else {
                // password mismatch
                throw response.data.message;
            }
        }
    }, {
        onSuccess: (_data, variables) => {
            //queryClient.invalidateQueries(['projects', variables.user_id], { refetchInactive: true });
        }
    });
}

export const useFetchBasicInfos = (username) => {
    return useQuery([username], async ()=> {
        let url = appConstants.BACKEND_ADDRESS + appConstants.BASIC_PAGE_ROUTE;
        if (username != null && username != undefined) {
            url = url + "?username=" + username;
        }
        let response = await axios.get(url);
    
        if (response.error) {
            // Problem occured, auto logout ... ?
            throw response.error;
        } else {
            if (response.status === 200) {
                // login succesful
                var hosts = response.data.response.hosts;
                if(hosts.length > 0) {
                    return hosts[0];
                }
                else {
                    throw 'account not found';
                }
            } else {
                // password mismatch
                throw response.data.message;
            }
        }
    });
}
