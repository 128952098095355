import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { userActions } from "../../redux/actions/";
import Localization from "../../utils/localization";
import Drawer from "./drawer";

const ResponsiveAppBar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const authed = useSelector((state) => state.users.loggedIn);
    const user = useSelector((state) => state.users.user);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [drawerOpened, setDrawerOpened] = React.useState(false);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleHome = () => {
        navigate("/");
    };

    const handleProfile = () => {
        setAnchorElUser(null);
        navigate("/profile");
    };

    const handleLogout = () => {
        setAnchorElUser(null);
        dispatch(userActions.logout());
        navigate("/signin");
    };

    const handleLogin = () => {
        setAnchorElUser(null);
        navigate("/signin");
    };

    const loggedInMenuItems = [
        { title: Localization.header.profile, handler: handleProfile, icon: <PersonIcon/>, available: true },
        { title: Localization.header.logout, handler: handleLogout, icon: <LogoutIcon/>, available: true },
    ];
    const loggedOutMenuItems = [{ title: Localization.header.login, handler: handleLogin, available: true }];

    const menuItems = authed ? loggedInMenuItems : loggedOutMenuItems;

    if(authed) {
        return (
            <AppBar position="static">
                <Drawer menu={loggedInMenuItems} toggleDrawer={(open)=>{setDrawerOpened(open);console.log("Assigning drawer openend to " + open);}} open={drawerOpened}/>
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{ mr: 2, display: { xs: "flex" } }}
                            onClick={handleHome}>
                            FanMetrics
                        </Typography>
    
                        <Box sx={{ flexGrow: 0, marginLeft: "auto" }}>
                            {/*<Tooltip title="Open settings">
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    <Avatar alt={user && user.username} src="/static/images/avatar/2.jpg" />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: "45px" }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}>
                                {menuItems.map((menuItem, index) => {
                                    return (
                                        <MenuItem key={index} onClick={menuItem.handler}>
                                            <Typography textAlign="center">{menuItem.title}</Typography>
                                        </MenuItem>
                                    );
                                })}
                            </Menu>*/}
                            <IconButton
                              aria-label="menu"
                              id="long-button"
                              aria-controls={drawerOpened ? 'long-menu' : undefined}
                              aria-expanded={drawerOpened ? 'true' : undefined}
                              aria-haspopup="true"
                              onClick={()=>{console.log("Assigning drawer openend to " + !drawerOpened);setDrawerOpened(!drawerOpened)}}
                            >
                              <MenuIcon color={"white"}/>
                            </IconButton>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        );
    }
    else {
        return (
            <AppBar position="static">
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{ mr: 2, display: { xs: "flex" } }}
                            onClick={handleHome}>
                            FanMetrics
                        </Typography>
    
                        <Box sx={{ flexGrow: 0, marginLeft: "auto" }}>
                            
                        {menuItems.map((menuItem, index) => {
                                return (
                                    <Button key={index} variant="outlined" color="white" onClick={menuItem.handler}>{menuItem.title}</Button>
                                );
                            })}
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        );
    }
};
export default ResponsiveAppBar;
