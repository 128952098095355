import React, { Component } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import RequireAuth from "./RequireAuth";
import Home from "../pages/Home";
import AccountOverview from "../pages/AccountOverview";
import Admin from "../pages/Admin";
import Signin from "../pages/Signin";
import Profile from "../pages/Profile";
import ConnectInstagram from "../pages/ConnectInstagram";
import ConnectTiktok from "../pages/ConnectTiktok";

class Router extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route
                        path="/admin"
                        element={
                            <RequireAuth>
                                <Admin />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/profile"
                        element={
                            <RequireAuth>
                                <Profile />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/connectinstagram"
                        element={
                            <RequireAuth>
                                <ConnectInstagram />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/connecttiktok/*"
                        element={
                            <RequireAuth>
                                <ConnectTiktok />
                            </RequireAuth>
                        }
                    />
                    <Route path="/:usernameParam" element={<AccountOverview />} />
                    <Route path="/signin" element={<Signin />} />
                </Routes>
            </BrowserRouter>
        );
    }
}

export default Router;
