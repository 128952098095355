import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const OutlinedButton = (props) => {
  return (
    <Box
      sx={{
        bgcolor: "primary.dark",
        color: "white.main",
        alignItems: "center",
        borderRadius: "0.4rem",
        border: "1px solid white",
        padding: "0.2rem 0.5rem",
        margin: "0.2rem 0.5rem",
        width: props.minWidth ? "8rem" : "auto",
      }}
    >
      <Typography textAlign="center">{props.text}</Typography>
    </Box>
  );
};
export default OutlinedButton;
