import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from '@tanstack/react-query'
import { useNavigate, useLocation, useParams, useSearchParams  } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Localization from "../../utils/localization";

import Header from "../../components/Header";

import { userActions, tiktokActions } from "../../redux/actions/";
import { useConnectTiktok } from "../../react-query/userServices";

const ConnectTiktok = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [urlParams, setUrlParams] = useSearchParams();
    const user = useSelector((state) => state.users.user);
    const connectingInstagram = useSelector((state) => state.users.connectingInstagram);
    const [initialScroll, setInitialScroll] = React.useState(false);

    const connectTiktok = useConnectTiktok();

    // Fetch Facebook Pages
    useEffect(() => {
        console.log("Code: " + JSON.stringify(urlParams.get("code")));
        if(urlParams.get("code")) {
            console.log("Fetching " + JSON.stringify(urlParams.get("code")));
            connectTiktok.mutate(urlParams.get("code"));
            //dispatch(tiktokActions.fetchAccessToken(urlParams.get("code")));
        }
    }, [urlParams]);

    const renderPages = () => {
        if (connectTiktok.isLoading) {
            return <CircularProgress color="inherit" />;
        } else  {
            return (
                <Box
                    maxWidth="sm"
                    sx={{
                        width: 1,
                        marginLeft: "auto",
                        marginRight: "auto",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}>
                    <Typography
                        variant="section-title"
                        sx={{
                            color: "black",
                            width: 1,
                            padding: "1rem",
                        }}>
                        {Localization.connectinstagram.selectPage}
                    </Typography>
                    {/*facebookStore.facebookPages.map((facebookPage, index) => {
                        return (
                            <Typography
                                key={index}
                                variant="section-text"
                                sx={{
                                    color: "black",
                                    width: 1,
                                    padding: "0.5rem",
                                }}
                                onClick={() => {
                                    handleSelectPage(facebookPage);
                                }}>
                                {facebookPage.name}
                            </Typography>
                        );
                    })*/}
                </Box>
            );
        }
    };

    return (
        <Box>
            <Header />
            <Container
                component="main"
                maxWidth="md"
                sx={{
                    paddingLeft: "0 !important",
                    paddingRight: "0 !important",
                }}>
                <CssBaseline />
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.loading + 1 }}
                    open={connectTiktok.isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box
                    sx={{
                        width: 1,
                        bgcolor: "primary.main",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginBottom: "1rem",
                    }}>
                    <Box
                        maxWidth="sm"
                        sx={{
                            width: 1,
                            bgcolor: "primary.main",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}>
                        <Avatar
                            alt={user && user.username}
                            src={user && user.preSignedProfileImageURL}
                            sx={{
                                width: { xs: "8rem", sm: "8rem" },
                                height: { xs: "8rem", sm: "8rem" },
                                border: "0.25rem solid #000",
                            }}
                        />
                        <Typography textAlign="center" variant="fancy-outline">
                            {user && user.Username}
                        </Typography>
                    </Box>
                </Box>
                <Box
                    maxWidth="sm"
                    sx={{
                        width: 1,
                        marginLeft: "auto",
                        marginRight: "auto",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}>
                    {renderPages()}
                </Box>
            </Container>
        </Box>
    );
};
export default ConnectTiktok;
