import axios from "axios";
import { appConstants } from "../../utils/config";
import { cryptPassword } from "../utils/encryption";

export const userServices = {
    login,
    logout,
    logoutFacebook,
    connectInstagram,
};

function login(email, password) {
    let username = email;
    // Encrypt the password
    let cryptedPassword = cryptPassword(password);
    return new Promise(function (resolve, reject) {
        axios
            .post(appConstants.FANSTORIES_BACKEND_ADDRESS + appConstants.LOGIN_ROUTE, {
                username,
                password: cryptedPassword,
            })
            .then((response) => {
                if (response.error) {
                    // Problem occured, auto logout ... ?
                    logout();
                    window.location.reload(true);
                    reject(response.error);
                } else {
                    if (response.status === 200) {
                        // login succesful
                        var loggedAccount = null;
                        let accountType = "host";
                        if (response.data.response.host) {
                            loggedAccount = response.data.response.host;
                            // console.log("Logged host with id " + loggedAccount.HostID);
                            accountType = "host";
                        } else if (response.data.response.user) {
                            loggedAccount = response.data.response.user;
                            //   console.log("Logged user with id " + loggedAccount.UserID);
                            accountType = "user";
                        }
                        var token = response.data.response.token;
                        localStorage.setItem("user", JSON.stringify(loggedAccount));
                        localStorage.setItem("token", token);
                        axios.defaults.headers.common["Authorization"] = token;
                        resolve(loggedAccount);
                    } else if (response.status === 403) {
                        // password mismatch
                        reject(response.data.message);
                    } else if (response.status === 400) {
                        // password mismatch
                        reject(response.data.message);
                    }
                }
            })
            .catch(function (error) {
                if (error.response) {
                    console.log("Error msg: " + error.response.data.message);
                    reject(error.response.data.message);
                } else {
                    //  console.log("Username/E-mail or password incorrect.");
                    reject("Username/E-mail or password incorrect.");
                }
            });
    });
}

function logout() {
    // Remove user from local storage to log user out
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    delete axios.defaults.headers.common["Authorization"];
    return true;
}

function logoutFacebook() {
    return new Promise(function (resolve, reject) {
        window.FB.api("/me/permissions", "delete", null, () => {
            window.FB.logout(() => {
                resolve();
            });
        });
    });
}

function connectInstagram(instagramUsername, instagramPageId, facebookAccessToken) {
    return new Promise(function (resolve, reject) {
        axios
            .post(appConstants.FANSTATS_BACKEND_ADDRESS + appConstants.INSTAGRAM_ROUTE + appConstants.CONNECT_METHOD, {
                username: instagramUsername,
                pageId: instagramPageId,
                accessToken: facebookAccessToken,
            })
            .then((response) => {
                //  console.log(response);
                if (response.error) {
                    // Problem occured, auto logout ... ?
                    logout();
                    window.location.reload(true);
                    reject(response.error);
                } else {
                    if (response.status === 201) {
                        // login succesful
                        let instagramConnectedAccount = response.data.response.instagramAccount;
                        resolve(instagramConnectedAccount);
                    } else if (response.status === 403) {
                        // password mismatch
                        reject(response.data.message);
                    } else if (response.status === 400) {
                        // password mismatch
                        reject(response.data.message);
                    }
                }
            })
            .catch(function (error) {
                if (error.response) {
                    console.log("Error msg: " + error.response.data.message);
                    reject(error.response.data.message);
                } else {
                    //  console.log("Username/E-mail or password incorrect.");
                    reject("Username/E-mail or password incorrect.");
                }
            });
    });
}
