import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Localization from "../../utils/localization";
import { useFetchBasicInfos } from "../../react-query/userServices";

import Header from "../../components/Header";
import LightCard from "../../components/LightCard";
import FilledButton from "../../components/FilledButton";
import ConnectFacebookModal from "../../components/ConnectFacebookModal";
import instagramLogo from "../../assets/images/instagram_icon_600.png";
import facebookLogo from "../../assets/images/facebook_icon_512.png";
import tiktokLogo from "../../assets/images/tiktok_icon_1000.png";
import youtubeLogo from "../../assets/images/youtube_icon_1000.png";
import { tiktokActions } from "../../redux/actions/";

const Profile = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    //const user = useSelector((state) => state.users.user);
    const [user, setUser] = React.useState(null);
    const fetchingUser = useSelector((state) => state.tiktok.fetchingUser);
    const [connectingInstagram, setConnectingInstagram] = React.useState(false);
    const fetchBasicInfos = useFetchBasicInfos();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if(fetchBasicInfos.isSuccess && fetchBasicInfos.data) {
            setUser(fetchBasicInfos.data);
        }
    }, [fetchBasicInfos.isLoading, fetchBasicInfos.data, fetchBasicInfos.isSuccess, fetchBasicInfos.isError]);

    const handleFacebookResponse = (response) => {
        if (response && response.accessToken) {
            navigate("/connectinstagram", {
                state: { facebookToken: response.accessToken },
            });
        } else {
            // Something wrong ?
        }
    };

    const handleConnectInstagram = () => {
        setConnectingInstagram(true);
    };

    const handleConnectTiktok = () => {
        const csrfState = Math.random().toString(36).substring(2);
        let url = 'https://www.tiktok.com/auth/authorize/';
        url += '?client_key=awfnne20ua3fud6d';
        url += '&scope=user.info.basic';
        url += '&response_type=code';
        url += '&redirect_uri=https://www.fanstories.com/fanstats/';
        url += '&state=' + csrfState;
        window.location.href = url; 
        //dispatch(tiktokActions.fetchUser());
    };

    const renderConnectedAccounts = () => {
        let accountDisplayed = false;
        let instagramDisplayed = false;
        let facebookDisplayed = false;
        let tiktokDisplayed = false;
        let youtubeDisplayed = false;
        if (user && user.instagramAccounts && user.instagramAccounts.length > 0) {
            accountDisplayed = true;
            instagramDisplayed = true;
        }
        if (user && user.tiktokAccounts && user.tiktokAccounts.length > 0) {
            accountDisplayed = true;
            tiktokDisplayed = true;
        }
        if (user && user.facebookAccounts && user.facebookAccounts.length > 0) {
            accountDisplayed = true;
            facebookDisplayed = true;
        }
        if (user && user.youtubeAccounts && user.youtubeAccounts.length > 0) {
            accountDisplayed = true;
            youtubeDisplayed = true;
        }
        return (
            <Container
                component="main"
                maxWidth="md"
                sx={{
                    width: 1,
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "",
                    paddingLeft: "",
                }}>
                {instagramDisplayed && renderAccountsSection(Localization.socialMedias.instagram, instagramLogo, ()=>{}, "instagram", user.instagramAccounts)}
                {tiktokDisplayed && renderAccountsSection(Localization.socialMedias.tiktok, tiktokLogo, ()=>{}, "tiktok", user.tiktokAccounts)}
                {facebookDisplayed && renderAccountsSection(Localization.socialMedias.facebook, facebookLogo, ()=>{}, "facebook", user.facebookAccounts)}
                {youtubeDisplayed && renderAccountsSection(Localization.socialMedias.youtube, youtubeLogo, ()=>{}, "youtube", user.youtubeAccounts)}
                {!accountDisplayed && (
                    <Box
                        sx={{
                            width: 1,
                            display: "flex",
                            alignItems: "center",
                            marginTop: "2rem",
                            paddingLeft: "1rem",
                        }}>
                        <img style={{ width: "1rem", height: "1rem" }} src={instagramLogo} alt={"Instagram"} />
                        <Typography>{Localization.accountOverview.noinfoyet}</Typography>
                    </Box>
                )}

                </Container>
                );
    };

    const renderAccountsSection = (title, icon, collabHandler, themePrefix, accounts) => {
        return (
            <Box
                sx={{
                    width: 1,
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "2rem",
                }}>
                <Box
                    sx={{
                        width: 1,
                        display: "flex",
                        alignItems: "center",
                        marginTop: "2rem",
                    }}>
                    <img style={{ width: "1rem", height: "1rem" }} src={icon} alt={"Instagram"} />
                    <Typography
                        sx={{
                            color: "primary.main",
                            paddingLeft: "0.25rem",
                        }}
                        variant="section-title">
                        {title}
                    </Typography>
                    <FilledButton text={Localization.accountOverview.startcollab} colored></FilledButton>
                </Box>
                <Box
                    sx={{
                        width: 1,
                        display: "flex",
                        alignItems: "center",
                        marginTop: "0.5rem",
                    }}>
                    {accounts.map((account) => {
                        return (
                            <LightCard
                                lightColor={`${themePrefix}.main`}
                                darkColor={`${themePrefix}.darkk`}
                                textColor={`${themePrefix}.darkk`}
                                title={Localization.accountOverview.totalfollowers}
                                text={account.Followers}></LightCard>
                        );
                    })}
                </Box>
            </Box>
        );
    };

    const renderConnectSocialAccounts = () => {
        return (
            <Box
                maxWidth="sm"
                sx={{
                    width: 1,
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "flex",
                }}>
                <Box
                    sx={{
                        width: "fit-content",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        padding: "1rem",
                        border: "1px solid #d4d4d4",
                        borderRadius: "1rem",
                        margin: '0.5rem'
                    }}
                    onClick={handleConnectInstagram}>
                    <img style={{ width: "6rem", height: "6rem" }} src={instagramLogo} alt={"Instagram"} />
                </Box>
                <Box
                    sx={{
                        width: "fit-content",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        padding: "1rem",
                        border: "1px solid #d4d4d4",
                        borderRadius: "1rem",
                        margin: '0.5rem'
                    }}
                    onClick={handleConnectTiktok}>
                    <img style={{ width: "6rem", height: "6rem" }} src={tiktokLogo} alt={"Instagram"} />
                </Box>
            </Box>
        );
    };

    return (
        <Box>
            <ConnectFacebookModal
                open={connectingInstagram}
                handleClose={() => {
                    setConnectingInstagram(false);
                }}
                handleFacebookResponse={handleFacebookResponse}
            />
            <Header />
            <CssBaseline />
            <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.loading + 1 }} open={fetchBasicInfos.isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Container
                component="main"
                maxWidth={"false"}
                sx={{
                    paddingLeft: "0 !important",
                    paddingRight: "0 !important",
                }}>
                <Box
                    sx={{
                        width: 1,
                        bgcolor: "primary.main",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginBottom: "1rem",
                    }}>
                    <Box
                        maxWidth="sm"
                        sx={{
                            width: 1,
                            bgcolor: "primary.main",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}>
                        <Avatar
                            alt={user && user.username}
                            src={user && user.preSignedProfileImageURL}
                            sx={{
                                width: { xs: "8rem", sm: "8rem" },
                                height: { xs: "8rem", sm: "8rem" },
                                border: "0.25rem solid #000",
                            }}
                        />
                        <Typography textAlign="center" variant="fancy-outline">
                            {user && user.Username}
                        </Typography>
                    </Box>
                </Box>
            </Container>
            <Container
                component="main"
                maxWidth="md"
                sx={{
                    paddingLeft: "0 !important",
                    paddingRight: "0 !important",
                }}>
                <Box
                    maxWidth="sm"
                    sx={{
                        width: 1,
                        marginLeft: "auto",
                        marginRight: "auto",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}>
                    <Typography
                        textAlign="left"
                        variant="section-title"
                        sx={{
                            color: "primary.main",
                            width: 1,
                        }}>
                        {Localization.profile.connectedaccounts}
                    </Typography>
                    {renderConnectedAccounts()}
                    <Divider sx={{ width: 1, marginTop: "1rem", marginBottom: "1rem" }} />
                    <Typography
                        textAlign="left"
                        variant="section-title"
                        sx={{
                            color: "primary.main",
                            width: 1,
                        }}>
                        {Localization.profile.connectaccounts}
                    </Typography>
                    {renderConnectSocialAccounts()}
                </Box>
            </Container>
        </Box>
    );
};

export default Profile;
