import axios from "axios";
import { appConstants } from "../../utils/config";

export const facebookServices = {
    getFacebookPages,
    getInstagramPageID,
    getLongLivedAccessToken,
    getInstagramPageInfos,
};

function getFacebookPages(facebookAccessToken) {
    return new Promise(function (resolve, reject) {
        let profileUrl = "https://graph.facebook.com/v13.0/me/accounts?access_token=" + facebookAccessToken;

        axios
            .get(profileUrl)
            .then((response) => {
                if (response.error) {
                    reject(response.error);
                } else {
                    if (response.status === 200 || response.status === "200") {
                        var pages = response.data.data;
                        resolve(pages);
                    } else if (response.status === 403) {
                        reject(response.data.message);
                    }
                }
            })
            .catch(function (error) {
                // handle error
                console.log(error);
                reject(error);
            });
    });
}

function getInstagramPageID(facebookAccessToken, facebookPageID) {
    return new Promise(function (resolve, reject) {
        let query =
            "https://graph.facebook.com/v13.0/" +
            facebookPageID +
            "?fields=instagram_business_account&access_token=" +
            facebookAccessToken;

        axios
            .get(query)
            .then((response) => {
                if (response.error) {
                    reject(response.error);
                } else {
                    if (response.status === 200 || response.status === "200") {
                        var instagramID = response.data.instagram_business_account.id;
                        resolve(instagramID);
                    } else if (response.status === 403) {
                        reject(response.data.message);
                    }
                }
            })
            .catch(function (error) {
                // handle error
                console.log(error);
                reject(error);
            });
    });
}

function getInstagramPageInfos(facebookAccessToken, instagramPageID) {
    return new Promise(function (resolve, reject) {
        let query =
            "https://graph.facebook.com/v13.0/" +
            instagramPageID +
            "?fields=id,ig_id,followers_count,username&access_token=" +
            facebookAccessToken;
        axios
            .get(query)
            .then((response) => {
                if (response.error) {
                    reject(response.error);
                } else {
                    if (response.status === 200) {
                        var infos = response.data;
                        resolve(infos);
                    } else if (response.status === 403) {
                        reject(response.data.message);
                    }
                }
            })
            .catch(function (error) {
                // handle error
                reject(error);
            });
    });
}

function getInstagramPageFollowersCount(facebookAccessToken, instagramPageID) {
    return new Promise(function (resolve, reject) {
        let query =
            "https://graph.facebook.com/v13.0/" +
            instagramPageID +
            "?fields=id,ig_id,followers_count,username&access_token=" +
            facebookAccessToken;
        axios
            .get(query)
            .then((response) => {
                if (response.error) {
                    reject(response.error);
                } else {
                    if (response.status === 200) {
                        var followers_count = response.data.followers_count;
                        resolve(followers_count);
                    } else if (response.status === 403) {
                        reject(response.data.message);
                    }
                }
            })
            .catch(function (error) {
                // handle error
                reject(error);
            });
    });
}

function getLongLivedAccessToken(facebookAccessToken) {
    // https://graph.facebook.com/{graph-api-version}/oauth/access_token?grant_type=fb_exchange_token&client_id={app-id}&client_secret={app-secret}&fb_exchange_token={your-access-token}
    return new Promise(function (resolve, reject) {
        let query =
            "https://graph.facebook.com/v13.0/oauth/access_token?grant_type=fb_exchange_token&client_id=" +
            appConstants.FACEBOOK_APP_ID +
            "&client_secret=" +
            appConstants.FACEBOOK_APP_SECRET +
            "&fb_exchange_token=" +
            facebookAccessToken;
        axios
            .get(query)
            .then((response) => {
                if (response.error) {
                    reject(response.error);
                } else {
                    if (response.status === 200) {
                        var longLivedAccessToken = response.data.access_token;
                        resolve(longLivedAccessToken);
                    } else if (response.status === 403) {
                        reject(response.data.message);
                    }
                }
            })
            .catch(function (error) {
                // handle error
                reject(error);
            });
    });
}

function queryInstagramPageInsights(facebookAccessToken, instagramPageID) {
    return new Promise(function (resolve, reject) {
        let query =
            "https://graph.facebook.com/v13.0/" +
            instagramPageID +
            "/insights?metric=impressions,reach,profile_views,follower_count&period=day&access_token=" +
            facebookAccessToken;
        axios
            .get(query)
            .then((response) => {
                if (response.error) {
                    reject(response.error);
                } else {
                    if (response.status === 200) {
                        var stats = response.data.data;
                        if (stats.length > 0) {
                        }
                        resolve(stats);
                    } else if (response.status === 403) {
                        reject(response.data.message);
                    }
                }
            })
            .catch(function (error) {
                // handle error
                reject(error);
            });
    });
}
